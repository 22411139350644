import React from 'react';

// styles
import styles from './landing.module.css';

// icons
import {ReactComponent as Emblem} from "./../../icons/emblem_glow.svg";

const Landing = props => {
    return (
        <div className={styles.background} style={{backgroundImage: 'url(https://deinstruct-v4.s3.us-east-2.amazonaws.com/background.png)'}}>
            <div className={styles.name}>
                <h1>DEINSTRUCT</h1>
            </div>
            <Emblem className={styles.emblem}/>
            <p className={styles.comingSoon}>COMING SOON</p>
        </div>
    )
}

export default Landing;